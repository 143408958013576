import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/features/blog/layouts/PostLayout/PostLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
  .material-theme {
    background-color: #263238;
    color: #EEFFFF;
  }
  .material-theme .mtki { font-style: italic; }
  .material-theme .mtk3 { color: #89DDFF; }
  .material-theme .mtk1 { color: #EEFFFF; }
  .material-theme .mtk12 { color: #C3E88D; }
  .material-theme .mtk8 { color: #82AAFF; }
  .material-theme .mtk4 { color: #546E7A; }
  .material-theme .mtk7 { color: #FFCB6B; }
  .material-theme .mtk6 { color: #F78C6C; }
  .material-theme .mtk10 { color: #C792EA; }
  .material-theme .grvsc-line-highlighted::before {
    background-color: var(--grvsc-line-highlighted-background-color, rgba(255, 255, 255, 0.1));
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, rgba(255, 255, 255, 0.5));
  }
`}</style>


    <p>{`So I am playing around with a hobby project in Lumen, and I had a few issues setting up Socialite with `}<a parentName="p" {...{
        "href": "https://lumen.laravel.com/"
      }}>{`Lumen`}</a>{`.`}</p>
    <p>{`Anyhow, as it often is with development, after a bit of persistence, I found a solution.`}</p>
    <p>{`All I really needed was to fetch user details from a Facebook Access Token, that I have already fetched in another application.`}</p>
    <h3>{`Step 1: Install`}</h3>
    <p>{`Follow installation guide from the official documentation: `}<a parentName="p" {...{
        "href": "https://laravel.com/docs/8.x/socialite#installation"
      }}>{`https://laravel.com/docs/8.x/socialite#installation`}</a></p>
    <h3>{`Step 2: Create a `}<inlineCode parentName="h3">{`config/services.php`}</inlineCode>{` file`}</h3>
    <p>{`Manually create config/services.php folder in the root of your Lumen project.`}</p>
    <p>{`This config folder is not default in Lumen and you'll have to manually create it. Mine looks like this:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "php",
      "data-index": "0"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<?`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`php`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`return`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`[`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`facebook`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`[`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`client_id`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`env`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`('`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`FACEBOOK_CLIENT_ID`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'),`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`client_secret`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`env`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`('`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`FACEBOOK_CLIENT_SECRET`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'),`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`redirect`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`http://example.com/callback-url`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`]`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`];`}</span></span></span></code></pre>
    <p>{`Depending on your project, you might need to add more to your configuration.`}</p>
    <h3>{`Step 3: Modify `}<inlineCode parentName="h3">{`bootstrap/app.php`}</inlineCode></h3>
    <p>{`In order for Lumen to register the newly created `}<inlineCode parentName="p">{`config/services.php`}</inlineCode>{` file, we'll need to modify `}<inlineCode parentName="p">{`bootstrap/app.php`}</inlineCode>{` a bit to look for this configuration, as well as registering the `}<inlineCode parentName="p">{`ServiceProvider`}</inlineCode>{` for Socialite.`}</p>
    <p>{`Under "Register Config Files" add: `}<code parentName="p" {...{
        "className": "material-theme",
        "data-language": "php",
        "data-index": "0"
      }}><span parentName="code" {...{
          "className": "mtk3"
        }}>{`$`}</span><span parentName="code" {...{
          "className": "mtk1"
        }}>{`app`}</span><span parentName="code" {...{
          "className": "mtk3"
        }}>{`->`}</span><span parentName="code" {...{
          "className": "mtk8"
        }}>{`configure`}</span><span parentName="code" {...{
          "className": "mtk3"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "mtk3"
        }}>{`'`}</span><span parentName="code" {...{
          "className": "mtk12"
        }}>{`services`}</span><span parentName="code" {...{
          "className": "mtk3"
        }}>{`'`}</span><span parentName="code" {...{
          "className": "mtk3"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "mtk3"
        }}>{`;`}</span></code></p>
    <pre {...{
      "className": "grvsc-container grvsc-has-line-highlighting material-theme",
      "data-language": "php",
      "data-index": "1"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`/*`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`|--------------------------------------------------------------------------`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`| Register Config Files`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`|--------------------------------------------------------------------------`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`|`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`| Now we will register the "app" configuration file. If the file exists in`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`| your configuration directory it will be loaded; otherwise, we'll load`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`| the default version. You may register other files below as needed.`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`|`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`*/`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`$`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`app`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`->`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`configure`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`('`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`app`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`');`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-add"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-add",
            "aria-hidden": "true",
            "data-content": "+"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`$`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`app`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`->`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`configure`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`('`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`services`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`');`}</span></span></span></code></pre>
    <p>{`And then register your `}<inlineCode parentName="p">{`SocialiteServiceProvider`}</inlineCode>{` in the `}<inlineCode parentName="p">{`ServiceProviders`}</inlineCode>{`:`}</p>
    <pre {...{
      "className": "grvsc-container grvsc-has-line-highlighting material-theme",
      "data-language": "php",
      "data-index": "2"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`/*`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`|--------------------------------------------------------------------------`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`| Register Service Providers`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`|--------------------------------------------------------------------------`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`|`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`| Here we will register all of the application's service providers which`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`| are used to bind services into the container. Service providers are`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`| totally optional, so you are not required to uncomment this line.`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`|`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`*/`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`$`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`app`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`->`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`register`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`Flipbox`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`\\`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`LumenGenerator`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`\\`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`LumenGeneratorServiceProvider`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`::`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`);`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// $app->register(App\\Providers\\AppServiceProvider::class);`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// $app->register(App\\Providers\\AuthServiceProvider::class);`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// $app->register(App\\Providers\\EventServiceProvider::class);`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-add"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-add",
            "aria-hidden": "true",
            "data-content": "+"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`$`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`app`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`->`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`register`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`Laravel`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`\\`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`Socialite`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`\\`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`SocialiteServiceProvider`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`::`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`);`}</span></span></span></code></pre>
    <h3>{`Hurray, it `}<em parentName="h3">{`should`}</em>{` now work`}</h3>
    <p>{`At least it did for me. Now in my `}<inlineCode parentName="p">{`routes/web.php`}</inlineCode>{` I can use Socialite like this:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "php",
      "data-index": "3"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`$`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`router`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`->`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`get`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`('`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`/get-facebook-user`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`function`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`$`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`user `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Socialite`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`::`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`driver`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`('`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`facebook`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`')->`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`userFromToken`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`('`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`SOME_TOKEN`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`');`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`dd`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`($`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`user`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`);`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// the facebook user`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`});`}</span></span></span></code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      